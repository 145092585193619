import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import PrimaryLinkButton from '../components/Button/PrimaryLinkButton'
import { Link } from "gatsby"
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import BreakpointDown from '../components/Media/BreakpointDown'


const GroceryTitle = styled.h1`

`
const GroceryRow = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  justify-content:space-between;
  margin-top:20px;
`
const GroceryColumn = styled.div`
 flex:0 0 50%;
 width:50%;
 padding-left:15px;
 padding-right:15px;
 ${BreakpointDown.xl`
  flex:0 0 100%;
  width:100%; 
 `}
 .gatsby-image-wrapper{ width:100%;}
 &:first-child{
   padding-right:60px;
   ${BreakpointDown.xl`
      flex:0 0 100%;
      width:100%;
      padding-right:15px;
    `}
 }
 &:last-child{
   padding-top:40px;
   ${BreakpointDown.md`
     padding-top:0px;
   `}
 }
`


// GroceryIndustry
const SectionBoxShadow = styled(Section)`
  box-shadow: 0px 20px 85px #00000033;
  z-index:2;
`
const GroceryIndustryTitle = styled.h2`
  max-width: 768px;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
`

const GroceryIndustryList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:60px;
 border-bottom:1px solid #CCCCCC;
 padding-bottom:60px;
 ${BreakpointDown.xl`
   margin-top:30px;
   padding-bottom:30px;
 `}
 &:nth-child(odd){
   flex-direction:row-reverse;
   &:last-child{border-bottom:0; padding-bottom:0;}
   .industry-left{
  &:before{
    right:auto;
    margin-right:0;
    left: 0;
    top: 35px;
    margin-left: -60px;
  }
   }
   .industry-right{
    padding-right: 90px;
    padding-left:0;
    ${BreakpointDown.xl`
      padding-right: 0px;
    `}
   }
 }
`
const GroceryIndustryListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
 flex:0 0 100%;
`}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
     display:none;
  `}
}
`
const GroceryIndustryListRight = styled.div`
  flex:1;
  padding-left:90px;
  padding-top:20px;
  ${BreakpointDown.xl`
    padding-left: 0px;
  `}
    ${BreakpointDown.md`
     padding-top:10px;
  `}
`
const GroceryIndustryListRightTitle = styled.div`
${BreakpointDown.md`
  margin-bottom:0;
`}
`
const GroceryIndustryListRightContent = styled.div`
 p{
   font-style:normal;
 }
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
`


function AerospacePage({ content = false }) {
  return (
    <Layout pageName="industry">
      <SEO
        title="Most Reliable Business Technology Solution for Aerospace Industry"
        description="Organizations in the Aerospace industry have very specific business technology needs. Contact  WYN Technologies for reliable and tailor-made technology solutions for your Aerospace business."/> 
      <Section pt="174px" pb="80px" bgColor="#F8F8F8" xpt="120px" xpb="60px" mpt="90px" mpb="40px">
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link><Link to="/#">Aerospace</Link>
          </BreadCrumb>
          <GroceryRow>
            <GroceryColumn>
              <GroceryTitle>Business Technology Solutions for the Aerospace Industry</GroceryTitle>
              <p>The Wright Brothers first took flight here way back in 1903, and North Carolina has been a major player in the aerospace industry ever since. NC is home to the 2nd fastest-growing aerospace cluster in the United States, including over 200 aerospace companies and over 400 aerospace suppliers. On top of that, NC boasts 4 international airports, dozens of regional and local public airports, and 3 major military bases.</p>
              <p>And here’s one thing that all of these aerospace businesses have in common – they all have their own respective business technology needs. Are you an aerospace business leader or office manager in need of a special technology installation? Then look no further than <strong>WYN Technologies</strong>!</p>
            </GroceryColumn>
            <GroceryColumn>
              <StaticImage
                src="../images/industry-1.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Brands We Trust"
                placeholder="blurred"
              />
            </GroceryColumn>
          </GroceryRow>
        </div>
      </Section>
      <SectionBoxShadow bgColor="#F8F8F8" pt="80px" pb="80px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <GroceryIndustryTitle className="h1">Some of the Services We Offer for Aerospace Businesses and Airports in NC</GroceryIndustryTitle>

          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Voice and Data Cabling Installation</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Having the right voice/data cabling network is important to most modern businesses, but it’s even more critical for aerospace businesses, organizations, and industries. Thankfully, <strong>WYN Technologies</strong> specializes in designed and installing effective structured cabling and related network racking solutions. Got some existing cabling in need of remediation services? We can take care of that for you, as well.</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">WiFi Access Points & Heat Mapping Services</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Having an effective wireless network is another necessity for airports and aerospace businesses. Your employees need it, your customers & clients expect it, and <strong>WYN</strong> can take care of it for you! We can set up new wireless access points for you, and can also leverage wireless heat mapping to identify any issues or problem spots with your current WiFi coverage.</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Moves, Adds, & Changes (MAC Services)</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>The aerospace industry is ever changing, moving, and growing. Going through a facility remodel, or planning a move to a new or different location? <strong>WYN</strong> can help things go more smoothly by providing comprehensive technology de-installation, relocation, and new installation services. Plus, we can help set up your structured cabling, wireless network access points, surveillance & security system, and much more.</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
<GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">RFID Tags, Readers, Tracking Equipment, & RFID Solutions for Aviation</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Airlines, airports, aircraft manufacturers, aircraft maintenance services, and others within the aerospace industry rely upon Radio Frequency Identification (RFID) systems to help track everything from valuable assets, to equipment, components, parts, and even passenger baggage. RFID facilitates more efficient inventory & logistics management, and does so with much less required manual labor or effort. And the best news? <strong>WYN</strong> can get you equipped with RFID for your aerospace organization, too!</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">IT Support Services</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Wish you had some ongoing business technology IT support for your aerospace group? We hear you. One of the most uncomfortable feelings as a business leader is finding yourself being “ghosted” by your technology provider. Fortunately, that’s not how <strong>WYN Technologies</strong> operates. We’re pleased to offer service-after-the-sale contracts to help keep all your equipment running smoothly for you – that’s one less headache you’ll have to worry about!</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Equipment Staging, Warehousing, & Storage Services</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p><strong>WYN</strong> has a complete staging facility for getting your new equipment set up and ready to go, equipped with a secure data closet, limited access control, and state-of-the-art surveillance. Need someplace to help manage your inventory and assets? We also have a large warehousing facility, and an even larger facility for other secure storage needs.</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>

        </div>
      </SectionBoxShadow>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
        <div className="container">
          <MarkdownContentWrapperInner>
          <MarkdownContentTitle>
                {/* <h2 className="h1">VoIP Technologies for Aerospace Businesses</h2> */}
              </MarkdownContentTitle>
                         <MarkdownContent>
                <h2>VoIP Technologies for Aerospace Businesses</h2>
                <p>Many enterprises have adopted hosted VoIP systems as their go-to communications solution. The many built-in features and technological advances of these systems are certainly attractive in their own right, but when you factor in the fact that they typically require minimal infrastructure investment, are cheaper to operate, and are easily scalable, VoIP is really pretty hard to beat as a business telephone and communications system.</p>
                <p>And as it concerns airlines and airports, the US FAA is now mandating VoIP interoperability for Air Traffic Control (ATC) and other key areas. Most major airports have already moved to an IP-based network, and adopting VoIP technology can deliver many benefits for airline call centers and other aerospace-related call centers, too. If you’re looking for the right business telephone and communications solution for your business or organization, <strong>WYN Technologies</strong> can make that happen for you!</p>
              </MarkdownContent>
              <MarkdownContent>
                <h2>Why Choose WYN Technologies for Your Aerospace Business Technology Needs</h2>
                <ul>
                  <li>
                    <strong>Tested-and-Approved Custom Solutions for You</strong>
                    <p>At WYN, we’re specially equipped to be able to fully stage, configure, and test your chosen devices and products ahead of time in our dedicated staging facility, before we ever come out to install.</p>
                  </li>
                  <li>
                    <strong>Only the Highest-Quality Products & Services</strong>
                    <p>WYN Technologies only partners with the best business technology brands and manufacturers. And our level of installation workmanship and ongoing personalized service for our clients is really second to none!</p>
                  </li>
                  <li>
                    <strong>We Know How to Work Within Your Budget</strong>
                    <p>WYN is ultimately here to help your business succeed. We understand corporate budgets & finances, and we can deliver the best technological solution to meet both your needs and your budget!</p>
                  </li>
                  <li>
                    <strong>Fast, Responsive 24/7 Customer Support</strong>
                    <p>Unlike some other technology providers, WYN is committed to be here for you for the long run. We actually answer the phone when you call, and we’ll respond promptly to your needs 24/7.</p>
                  </li>
                </ul>
              </MarkdownContent>
                 <MarkdownContent>
                <h2>Got Questions? Ready to Get Started?</h2>
                <p>Need to plan some business technology upgrades for your facility? WYN Technologies is happy to come out and do a free initial site evaluation and consultation with you. Have some other specific questions? Just reach out to us today at <a href="tel:3368990555">(336) 899-0555</a>, and let’s get the conversation started!</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
        </div>
      </Section>
    </Layout>
  )
}
export default AerospacePage
